
import './App.css';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";

import { useEffect } from "react";

import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';

import Gallery from './pages/Gallery';
import Pose from './pages/Pose';
import Price from './pages/Price';

import Contact from './pages/Contact';
import Footer from './components/Footer';
import Team from './pages/Team';

import ScrollToTop from './components/ScrollToTop';

import BlogDetails from './pages/BlogDetails';
import WhatsAppButton1 from './components/WhatsAppButton1';

import Consultation from './pages/Consultation';
import Heathylifestle from './pages/Heathylifestle';
import Diet from './pages/Diet';
import Counselleing from './pages/Counselleing';
import Trainingyoga from './pages/Trainingyoga';
import Seminars from './pages/Seminars';
import Stressdeletion from './pages/Stressdeletion';
import Acupenture from './pages/Acupenture';
import Yoga from './pages/Yoga';
import ScrollToTopButton1 from './components/ScrollToTopButton1';
import ReachUsButton from './components/ReachUsButton';
import Slide from './components/Slide';
import Naturopathy from './pages/Naturopathy';
import YogaWebsite from './pages/Doctorteam';
import Videoconsultancy from './components/Videoconsultancy';
import StaffPage from './pages/StaffTeam';
import Slider from './components/slider/Slider';
import DoctorDesc from './pages/DoctorDesc';
import CorparateClinic from './pages/CorparateClinic';

// import Whatsapp from './components/Whatsapp';




function App() {


    useEffect(() => {
        (function ($) {
            "use strict";



            // Sticky Navbar
            $(window).scroll(function () {
                if ($(this).scrollTop() > 0) {
                    $('.navbar').addClass('nav-sticky');
                } else {
                    $('.navbar').removeClass('nav-sticky');
                }
            });


            // Dropdown on mouse hover
            $(document).ready(function () {
                function toggleNavbarMethod() {
                    if ($(window).width() > 992) {
                        $('.navbar .dropdown').on('mouseover', function () {
                            $('.dropdown-toggle', this).trigger('click');
                        }).on('mouseout', function () {
                            $('.dropdown-toggle', this).trigger('click').blur();
                        });
                    } else {
                        $('.navbar .dropdown').off('mouseover').off('mouseout');
                    }
                }
                toggleNavbarMethod();
                $(window).resize(toggleNavbarMethod);
            });




        })($);
    }, [])
    return (
        <>

            <Navbar />
            <ScrollToTop/>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/services' element={<Services />} />

                <Route path='/gallery' element={<Gallery />} />
                <Route path='/pose' element={<Pose />} />
                <Route path='/price' element={<Price />} />
                <Route path='/team' element={<Team />} />

                <Route path='/contact' element={<Contact />} />
                <Route path='/yoga' element={<Yoga />} />

                <Route path='/BlogDetails' element={<BlogDetails />} />
                <Route path='/consultation' element={<Consultation />} />
                <Route path='/heathylifestyle' element={<Heathylifestle />} />
                <Route path='/diet' element={<Diet />} />
                <Route path='/acupenture' element={<Acupenture />} />
                <Route path='/counselleing' element={<Counselleing />} />
                <Route path='/trainingyoga' element={<Trainingyoga />} />
                <Route path='/seminars' element={<Seminars />} />
                <Route path='/stressdeletion' element={<Stressdeletion />} />
                <Route path='/naturopathy' element={<Naturopathy />} />

                <Route path="/yoga" component={Yoga} />
                <Route path="/diet" component={Diet} />
                <Route path="/counselleing" component={Counselleing} />
                <Route path="/acupenture" component={Acupenture} />
                <Route path="/stressdeletion" component={Stressdeletion} />
                <Route path="/seminars" component={Seminars} />
                <Route path="/Videoconsultancy"  element={<Videoconsultancy />} />
                <Route path="/DoctorsDetails"  element={<DoctorDesc />} />
                <Route path="/CorporateClinic"  element={<CorparateClinic />} />
                <Route path="/staff" element={<StaffPage/>}/>






            </Routes>
            <Slide />

            <WhatsAppButton1 />



            {/* <Whatsapp/> */}
            <ScrollToTopButton1 />
            /


            <Footer />



        </>
    );
}


export default App;
