import React from 'react';
import '../lib/animate/animate.min.css';
import { FaYoutube } from 'react-icons/fa';
const Footer=()=> {
  return (
    <div class="footer wow fadeIn" data-wow-delay="0.3s" style={{backgroundColor:"#343148"}}>
            <div class="container-fluid">
                <div class="container">
                    <div class="footer-info">
                        <img style={{marginLeft:'-23px'}}src="star.png"/><br/>
                        <a href="/" class="footer-logo"><span></span><h2 style={{color: "white", fontSize:"38px"}}><b>AnantaSaukhyam</b></h2></a><br/>
                        <img style={{marginLeft:'-23px',height:'50px'}}src="logo9991.png"/><br/>
                        <div class="">
                            <p style={{fontSize:"25px",color:"white"}}>+916366889406</p>
                            

                            <a href="mailto:info@anantasaukhyam.com" style={{color:"white",fontSize:"20px"}}><b>info@anantasaukhyam.com</b></a>
                        </div>
                        <div class="footer-social">
                            {/* <a href=""><i class="fab fa-twitter"></i></a> */}
                           
                            <a href="https://www.youtube.com/watch?v=0VXeYTuFEmk" target="_blank"><FaYoutube size={30} color={"white"} /></a>
                        
                        </div>
                    </div>
                </div>
                <div class="container copyright">
                    <div class="row">
                        <div class="col-md-6">
                            <p>&copy; <a href="#"><b>2024 AnantaSaukhyam</b></a>, All Rights Reserved.</p>
                        </div>
                        <div class="col-md-6">
							
                            <p>Designed By <a href="https://www.excerptech.com/" target='_blank'> <b>Excerpt Technologies Pvt Ltd</b></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Footer;