
import React from 'react';
import './DoctorDesc.css';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const data = [
  {
    image: './DoctorTeam/swarna.jpeg', 
    text: 'Dr Swarna Gowri is practicing Yoga & Naturopathy since 2003, has over 20 years of experience treating patients using Naturopathy, Acupuncture, Counselling and Medical Yoga, not only in India, but across the world.She specializes in treating disorders such as chronic pain, hypertension, diabetes, high cholesterol, PCOS, infertility, Skin disorders, Allergies , Arthritis, sleep disorders among others...',
    name: 'Dr. Swarna Gowri'
  },
  {
    image: './DoctorTeam/Akshata.jpeg',
    text: 'Dr. Akshata,  a senior  Acupuncturist with 15 years of experience in the field.',
    name: ' Dr. Akshata Hallikere'
  },
  {
    image: './DoctorTeam/Poornima.jpeg',
    text: 'Dr Poornima B K , a wellness coach holds bachelor\'s degree in Naturopathy and yogic sciences from SDM college, ujire and Masters in Acupuncture through federation of Acupuncture board, Bangalore. Also has got trained at Jindal Nature cure hospital, Bangalore. With rich experience of 15+yrs in the field of Naturopathy and Yogic sciences she has treated various chronic conditions through clinical acupuncture, Diet and Nutrition counseling, Yoga therapy and other natural and drugless treatment modalities.',
    name: 'Dr. Poornima B K'
  },
  {
    image: './DoctorTeam/Vireeshka.jpeg',
    text: 'Dr. Vrisheeka Suresh , a senior naturopath with 17 years of expertise who integrates personalized, holistic treatment strategies that emphasize education on natural remedies and lifestyle corrections. Her approach includes therapies such as acupuncture, diet, and yoga. She has a special interest in, and significant experience with, working with ADHD and autism.',
    name: 'Dr. Vrisheeka Suresh'
  },
  {
    image: './DoctorTeam/Vireeshka.jpeg',
    text: 'Dr. Vrisheeka Suresh , a senior naturopath with 17 years of expertise who integrates personalized, holistic treatment strategies that emphasize education on natural remedies and lifestyle corrections. Her approach includes therapies such as acupuncture, diet, and yoga. She has a special interest in, and significant experience with, working with ADHD and autism.',
    name: 'Dr. Vrisheeka Suresh'
  },
];

const DoctorDesc = () => {
  return (
    <div>
    <div className="page-headerg" >
    <div className="container resposnive_breadmcrum">
        <div className="row">
            <div className="col-12">

                <h2 style={{ color: "black",fontFamily:"Circular" }}>Doctors</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
                <a style={{color:"black"}} href="/">Home</a>
                <a style={{color:"black"}} href="#"><MdKeyboardDoubleArrowRight/>Doctors</a>
            </div>
        </div>
    </div>
</div>
    <div className="alt-container">
      {data.map((item, index) => (
        <div key={index} className={`alt-row ${index % 2 === 0 ? 'alt-row-even' : 'alt-row-odd'}`}>
          <div className="alt-image-container">
            <img src={item.image} alt={`Item ${index + 1}`} />
            <p>{item.name}</p> {/* Display name under the image */}
          </div>
          <div className="alt-content-container">
            <p>{item.text}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default DoctorDesc;
