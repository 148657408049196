import React from "react";
import { Link } from "react-router-dom";
//import '../css/style.css';
// import '../lib/animate/animate.css';

// import  {define,useEffect} from "react";
// import $ from 'jquery';
// import 'jquery-ui-dist/jquery-ui';
//import WOW from 'wow.js';

// import $ from 'jquery';
// import "jquery-ui-dist/jquery-ui";

// import { useEffect } from "react";
// import '../lib/flaticon/font/flaticon.css';
// import '../lib/owlcarousel/assets/owl.carousel.min.css';
// import '../lib/lightbox/css/lightbox.min.css';

const About = () => {
  //     useEffect=()=>{

  //},[])

  return (
    <>
      <div className="about wow fadeInUp" data-wow-delay="0.2s " style={{marginTop:'-50px'}} >
        <div className="container">
          <div className="row align-items-center about wow fadeInUp">
            <div className="col-lg-5 col-md-6">
              <div className="about-img about-img1 ">
                <Link to="">
                  <img
                    src="img/yogaa12.jpg"
                    alt="yoga"
                    style={{ height: "400px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div
                className="section-header text-left"
                style={{ marginTop: "10px" }}
              >
                <p>Learn About Us</p>
                <h2>Welcome to AnantaSaukhyam</h2>
              </div>
              <div
                className="about-text about wow fadeInUp"
                data-wow-delay="0.5s" style={{marginTop:'-120px'}}
              >
                <br />
                <br />

                <p style={{ color: "black" }}>
                  <b>AnantaSaukhyam</b>{"  "}
                  The core purpose of Ananatasaukhyam is to empower an individual's natural healing abilities with minimal external intervention. Our goal is to elevate self-healing through a variety of holistic therapies.
                  {/* <b>AnantaSaukhyam</b> seeks to empower individuals to heal themselves through a holistic approach that minimizes external intervention. We aim to promote self-healing through various holistic therapies, including meditation, yoga, Ayurveda, and other complementary and alternative medicine (CAM) therapies. We believe that the body has the innate ability to heal itself and that all healing comes from within. Our holistic approach is designed to support the body's natural healing processes by addressing the root cause of illness. */}
                  <br/>Bringing Health to All,  <b>Enhancing QUALITY OF HUMAN LIFE. </b> Discover your highest potential—connect with your unlimited source. Experience infinity (Ananta) within yourself.
                                    
                  To reach one's own highest potential  Know your Unlimited Source. Experience the infinity (the Ananta) in <b>YOU.</b>

                </p>
                <p style={{ color: "black" }}>
                  <b>Vision  -</b> Enabling a Healthy Lifestyle for Individuals and to the Corporate World, Based on Personal Experiences. 
                  {/* <b>
                    Acupuncture, Pressure Therapy, Counseling, Diet & Lifestyle,
                    Yoga Therapy, Ayurveda, Homeopathy Consultations & Advices
                  </b>{" "}
                  will be given through our expertise in the field. */}
                </p>
                <p style={{ color: "black" }}>
                  <b> Mission -</b>  Driving a Holistic and Integrated Health Approach to Employee Well-Being by establishing an Integrated Clinic.This clinic addresses the needs of today’s employees through services such as {" "}
                  <b>
                  Acupuncture, Pressure Therapy, Counseling, Diet and Lifestyle Management, Yoga Therapy, Ayurveda, and Homeopathy consultations & treatments. 
                  </b>{" "}
                  {/* will be given through our expertise in the field. */}
                </p>
                <a className="btn" href="/about">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
