import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Heathylifestle = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
}, []);
  return (
    <div>
       <div className="page-headerh" >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{fontFamily:"Circular"}}>

              <h2 >Healthy LifeStyle</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight/>HealthyLifeStyle </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about wow fadeInUp" data-wow-delay="0.1s"  style={{marginTop:'-50px'}}>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
            <div >
                    <p><br /><br /></p>
                    
                </div>
                <div className="about-img">
                <img src="img1/banan2.1.jpg" alt="about" className="move-image" style={{marginTop:'-50px'}} />
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="section-header text-left" style={{marginTop:'10px'}}>
                    <p>Healthy Lifestyle</p>
                    <h2>Holistic Approach</h2><br />
                    
                </div>
                <div className="about-text" style={{marginTop:'-25px'}}>
                    <p>
                     
                    A good healthy lifestyle helps us to lead a healthy life
                    ,that includes activities and habits promoting total health through physical fitness, mental well-being, and spiritual growth. Can also reduce the risk of major illnesses. <br/>
                    Customizing a healthy lifestyle to fit one's personal circadian tendencies ensures you're living in harmony with your natural energy cycles, leading to better health, productivity, and overall well-being.<br/>
                   <br /><b style={{color:"black"}}>Healthy lifestyles are designed to understand the circadian rhythm of the Individual & help the person to get the best of their Life. </b>

                    </p>
                    <p>
                    Healthy lifeStyle (is not just food, exercise and sleep). It includes <br /><b>Ahara(food)</b> is both for body and mind.<br />
                    <b>Vihara</b> - activities done at both physical and mental level.<br />
                    <b>Vichara</b> Thoughts that become habits to actions. <br/>
                    <b>Achara</b> - Healthy conduct 
                    <br /> <br/>
                    
                    A healthy lifestyle is a continuous journey, not a one-time activity. It is an ongoing process that helps individuals upgrade themselves to a better version of who they are, enabling them to handle and implement positive changes in day-to-day life situations more effectively.</p>
                   
                   
                </div>
            </div>
        </div>
    </div>
</div>

{/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row align-items-center">
            
            <div className="col-lg-5 col-md-6">
                 <div className="about-text">
                 <p>
                   
                 3. Proper Hydration: Staying hydrated is essential for maintaining optimal health. In yoga, water is considered a vital element for cleansing the body and ensuring its proper functioning. Drinking an adequate amount of water throughout the day helps flush out toxins and keeps your body well-hydrated.<br />
4. Stress Management: Yoga and meditation techniques are powerful tools for managing stress. High levels of stress can have detrimental effects on your health, so incorporating relaxation practices into your routine can help you maintain a calm and balanced mind.<br />
5. Quality Sleep: Adequate sleep is crucial for physical and mental recovery. Yoga promotes healthy sleep patterns through relaxation exercises and poses that calm the nervous system. Getting enough restorative sleep enhances your overall well-being.

                   </p>
                </div>
            </div>
            <div className="col-lg-7 col-md-6">
                <div className="about-img">
                    <img src="img/kitchne.jpg" alt="about" style={{height:"400px",width:"600px"}}/>
                </div>
            </div>
        </div>
    </div>
</div> */}
    </div>
  )
}

export default Heathylifestle
