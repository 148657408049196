import React, { useEffect, useState } from 'react';
// import TestiMonialsDetails from '../TestiMonialsDetails/TestiMonialsDetails'
import TestiMonialsDetails from './TestiMonialsDetails';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import Appointment from './Appointment'
// import userPic from '../../image/user-one.png';
import './TestiMonials.css'

const TestiMonials = ({ testiMonialDetail }) => {

    const testiMonials = [
        {
            name: 'Acupuncture',
            description: 'Acupuncture is an age-old healing form of alternative medicine and a component practice of traditional Chinese medicine (TCM).',

            img: 'yog img1/Acupuncturist1.jpg',
            link:'/acupenture'

        },
        {
            name: 'Diet',
            description: 'The kind and amount of food selected for a person depends on a purpose, health and state of well being.',

            img: 'img/New folder/diet.jpeg',
            link:'/diet'
        },
        {
            name: 'Healthy Lifestyle',
            description: 'Health is wealth and is more important than anything. Having good health is a synonym for being physically and mentally fit.',

            img: 'img/hl.jpg',
            link:'/heathylifestyle'
        },
        {
            name: 'Stress Deletion',
            description: 'We believe in stress deletion rather than stress managment.',

            img: 'img/New folder/sd.jpg',
            link:'/stressdeletion'
        

        },
        {
            name: 'Yoga Training',
            description: 'One of the many benefits of yoga training is that you can use it to deepen your own practice.',

            img: 'yog img1/diet5.jpg',
            link:'/seminars'
        },
        {
            name: 'Naturopathy',
            description: 'We use simple methods of Life style modification with food,simple herbs   water  therapy,  balance  the thermodynamics of the Body.',

            img: 'img1/as1.jpg',
            link:'/naturopathy'

        },
        {
            name: 'Counseling',
            description: 'The Power of Mind - Understanding the mind & its levels of operations. These sessions are designed to help to create a space that’s required through deletion, restart, uninstall & install.',

            img: 'img1/as4.jpg',
            link:'/counselleing'

        },
        
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 10000,
        smartSpeed: 10000,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            },
            autoplay: true,
            autoplayHoverPause: true, // Pause on hover
        },

    };



    const [status, setStatus] = useState("Submit");

    const [info, setInfo] = useState({ name: '', mobilenumber: '', email: '', time: '', service: '' });

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Check if the field is 'name' and the value starts with a number
        if (name === 'name' && /^[0-9]/.test(value)) {
            // Display a warning or set a flag to indicate the issue
            console.log('Name should not start with a number');
        }
        setInfo({
            ...info,
            [event.target.name]: event.target.value
        })
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(info);
        setStatus("Sending...");


        let response = await fetch("/api/testiMonialspage", {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(info),
            //body: JSON.stringify(details),
        });
        //console.log(details);
        console.log(info);
        setStatus("Book an Appointment");
        setInfo({ name: '', mobilenumber: '', email: '', time: '', service: '' });
        let result = await response.json();
        //alert(result.status);
    };
    return (
        <section id="testimonial" className="testimonials pt-70 pb-70" style={{marginTop:'-130px'}}>

            <div className="row">

                <div className="col-md-12">
                    <div className="container mt-5">
                        <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                            <p></p>
                            <h2 style={{color:"#343148"}}>Our Services...</h2>
                        </div>
                    </div>


                    <OwlCarousel id="customer-testimonials" className="owl-carousel owl-theme" {...options}>
                        {testiMonials.length === 0 ? (
                            <div className="item testimonial-item">
                                <Link to={testiMonialDetail.link} style={{color:"black"}}>
                                    <div className="">
                                        <div className="img-container">
                                            <img src={testiMonialDetail.img} alt={testiMonialDetail.name} />
                                        </div>
                                    </div>
                                    <div className="testimonial-text">
                                        <p>{testiMonialDetail.description}</p>
                                        <div className="testimonial-name">
                                            <h5>{testiMonialDetail.name}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            testiMonials.map((testiMonialDetail, index) => (
                                <div className="item testimonial-item" key={index}>
                                    <Link to={testiMonialDetail.link} style={{color:"black"}}>
                                        <div className="">
                                            <div className="img-container">
                                                <img src={testiMonialDetail.img} alt={testiMonialDetail.name} />
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p style={{colr:"black",textAlign:"justify"}}>{testiMonialDetail.description}</p>
                                            <div className="testimonial-name">
                                                <h5>{testiMonialDetail.name}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        )}
                    </OwlCarousel>


                </div>
            </div>


            <Appointment />
         
        </section >
    );
};

export default TestiMonials;