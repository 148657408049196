import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import Topheader from "./Topheader";
import { IoCartOutline } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbShoppingCart } from "react-icons/tb";
import { VscAccount } from "react-icons/vsc";
import { FaTimes } from "react-icons/fa";
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './NavaBar.css'
import { useNavigate } from 'react-router-dom';
import { FaLinkedin } from 'react-icons/fa';
import { FaMap } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { FaSearch,    FaUserCircle, } from 'react-icons/fa';
const Navbar = () => {
    const navigate = useNavigate();
        const iconStyle = {
        marginRight: '10px',
        color:'white'
    };

    const buttonStyle = {
        fontFamily: "Open Sans, Sans Serif",
        marginTop: '1px',
        // marginLeft:'40px',
        color:'white',
        backgroundColor:'#24444f'
    };
    const desktopOnlyStyle = {
        display:'inline-block',
    };


    const navbarStyle = {
        width: "30px",
        height: "30px",
    };

    const hamburgerIconStyle = {
        width: "18px",
        transform: "translate(-7px, -6px)",
        color: "black",
    };
    const navsub = {
        color: '#636b2f',
        fontWeight:"bold",
    }
    const navLinkStyle = {
        color: 'black',
        fontWeight: "bold",
        // Added background color for mobile view
        // backgroundColor: isMobileView ? 'white' : 'transparent',
        // padding: isMobileView ? '10px 15px' : '0.5rem 1rem', // Adjust padding for better touch target in mobile
    };
    const handleNavLinkClick = (event) => {
        // Prevent closing the sidebar immediately
        event.preventDefault();
        
        // Delay the closing action to allow the page navigation to complete first
        setTimeout(() => {

            
            // Manually navigate to the target route
            navigate(event.target.getAttribute('href'));
        }, 300); // Set delay in ms (300ms should be enough)
    };
  return (
    <div>
        <Topheader />

            <div>
                <div className="row1 wrapper-header align-items-center navbar nav-sticky">
                    <div className="col-md-4 col-3 tf-lg-hidden">
                   

                      <Link to="#mobileMenu" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                                <path d="M2.00056 2.28571H16.8577C17.1608 2.28571 17.4515 2.16531 17.6658 1.95098C17.8802 1.73665 18.0006 1.44596 18.0006 1.14286C18.0006 0.839753 17.8802 0.549063 17.6658 0.334735C17.4515 0.120408 17.1608 0 16.8577 0H2.00056C1.69745 0 1.40676 0.120408 1.19244 0.334735C0.978109 0.549063 0.857702 0.839753 0.857702 1.14286C0.857702 1.44596 0.978109 1.73665 1.19244 1.95098C1.40676 2.16531 1.69745 2.28571 2.00056 2.28571ZM0.857702 8C0.857702 7.6969 0.978109 7.40621 1.19244 7.19188C1.40676 6.97755 1.69745 6.85714 2.00056 6.85714H22.572C22.8751 6.85714 23.1658 6.97755 23.3801 7.19188C23.5944 7.40621 23.7148 7.6969 23.7148 8C23.7148 8.30311 23.5944 8.59379 23.3801 8.80812C23.1658 9.02245 22.8751 9.14286 22.572 9.14286H2.00056C1.69745 9.14286 1.40676 9.02245 1.19244 8.80812C0.978109 8.59379 0.857702 8.30311 0.857702 8ZM0.857702 14.8571C0.857702 14.554 0.978109 14.2633 1.19244 14.049C1.40676 13.8347 1.69745 13.7143 2.00056 13.7143H12.2863C12.5894 13.7143 12.8801 13.8347 13.0944 14.049C13.3087 14.2633 13.4291 14.554 13.4291 14.8571C13.4291 15.1602 13.3087 15.4509 13.0944 15.6653C12.8801 15.8796 12.5894 16 12.2863 16H2.00056C1.69745 16 1.40676 15.8796 1.19244 15.6653C0.978109 15.4509 0.857702 15.1602 0.857702 14.8571Z" fill="currentColor"></path>
                            </svg>
                        </Link>

                    </div>
                    <div className="col-xl-5 tf-md-hidden">
                        <div className="box-navigation text-center" style={{display:'flex'}}>
                        <div className="box-nav-ul d-flex align-items-center gap-1">
                        <li className="menu-item"><Link to="/" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick} >Home</Link></li>
                        <li className="menu-item"><Link to="/about" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>About Us</Link></li>
                        <li className="menu-item"><div className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" style={navLinkStyle} onClick={handleNavLinkClick}>Services</Link>
                                 <div className="dropdown-menu">
                                    <Link to="/yoga" className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Yoga</Link>
                                    <Link to='/consultation' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Consultation</Link>
                                     <Link to='/heathylifestyle' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Healthy LifeStyle</Link>
                                    <Link to='/diet' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Diet</Link>
                                     <Link to='/acupenture' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Acupuncture</Link>
                                    <Link to='/counselleing' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Counseling</Link>
                                  <Link to='/trainingyoga' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Trainings on Yoga</Link>
                                   <Link to='/seminars' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Seminars to Corporate</Link>
                                    <Link to='/stressdeletion' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Stress Deletion</Link>
                                    <Link to='/naturopathy' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Naturopathy</Link>
                                    <Link to='/CorporateClinic' className="dropdown-item" style={navsub} onClick={handleNavLinkClick}>Corporate Clinic</Link>                                 </div>
                             </div></li>
                             <li className="menu-item"><Link to='/staff' className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Consultants</Link></li>
                            </div>
                     
                        </div>
                    </div>
                    <div className="col-xl-2 " >
                        <a href="/" className="logo-header">
                            <img src="./logo999.png" alt="logo" className='logonav'/>
                        </a>
                    </div>
                    <div className="col-xl-5 tf-md-hidden">
                        <nav className="box-navigation text-center d-flex nav2"  >
        
                        <ul className="box-nav-ul d-flex justify-content-end align-items-center gap-1">
                        <li className="menu-item"><Link to="/gallery" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Gallery</Link></li>

                        <li className="menu-item"><Link to="/BlogDetails" className="nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Blog</Link></li>
                           
                        <li className="menu-item"><Link to="/contact" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick}>Contact</Link></li>
                        <li className="menu-item"><Link to="/Videoconsultancy" style={desktopOnlyStyle }>
                                <button className="learm_more_button1" style={buttonStyle}>
                                    <FontAwesomeIcon icon={faVideo} style={iconStyle} /> Video Consultation<span style={{ color: "#797979", fontSize: '15px' }}></span>
                                 </button>
                            </Link> </li>
                            </ul>
                        </nav>
                    </div>
                  
                </div>
            </div>
        

   
   
    <div className="offcanvas offcanvas-start canvas-mb" id="mobileMenu">
        <span className="icon-close icon-close-popup" data-bs-dismiss="offcanvas" aria-label="Close"></span>
        <div className="mb-canvas-content">

        <ul className="nav-ul-mb" id="wrapper-menu-navigation">
        <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
<li className="nav-mb-item"><Link to="/" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Home</Link></li>
<li className="nav-mb-item"><Link to="/about" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">About Us</Link></li>
<li className="nav-mb-item"><div className="nav-item dropdown">
   <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Services</Link>
    <div className="dropdown-menu">
       <Link to="/yoga" className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Yoga</Link>
       <Link to='/consultation' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Consultation</Link>
        <Link to='/heathylifestyle' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Healthy LifeStyle</Link>
       <Link to='/diet' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Diet</Link>
        <Link to='/acupenture' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Acupuncture</Link>
       <Link to='/counselleing' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Counseling</Link>
     <Link to='/trainingyoga' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Trainings on Yoga</Link>
      <Link to='/seminars' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Seminars to Corporate</Link>
       <Link to='/stressdeletion' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Stress Deletion</Link>
       <Link to='/naturopathy' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Naturopathy</Link>
       <Link to='/CorporateClinic' className="dropdown-item" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Corporate Clinic</Link>                                 </div>
</div></li>
<li className="nav-mb-item"><Link to='/staff' className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Consultants</Link></li>
<li className="nav-mb-item"><Link to="/gallery" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Gallery</Link></li>

<li className="nav-mb-item"><Link to="/BlogDetails" className="nav-link" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Blog</Link></li>

<li className="nav-mb-item"><Link to="/contact" className="nav-item nav-link" style={navLinkStyle} onClick={handleNavLinkClick} data-bs-dismiss="offcanvas">Contact</Link></li>


</ul>
        </div>       
    </div>
  
    </div>
  )
}

export default Navbar;