import React from 'react';
import './WhatsAppButton1.css';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhatsAppButton1 = () => {
  return (
    <div class="rs-video whatsapp-fixed">
    <div class="animate-border">
        <a alt="Whatsapp" class="video-vemo-icon btn-whatsapp" aria-label="WhatsApp" href="http://api.whatsapp.com/send?1=pt_BR&amp;phone=916366889406" target="_blank" rel="noopener noreferrer" onclick="gtag_report_conversion(undefined)"><FontAwesomeIcon icon={faWhatsapp}  style={{width:"30px",height:"30px"}}/>
          <div class="sm-red-dot"></div>
          <div id="" class="">
            <p>
              
            </p>
            <div class="seta-direita">
            </div>
           </div>
        </a>
    </div>
    <div id="hover-message" class="">
        <p>
           
        </p>
       <div class="seta-direita">
       </div>
    </div>
</div>
  );
};

export default WhatsAppButton1;
<a id="simple-chat-button--button" href="https://web.whatsapp.com/send?phone=916366889406&amp;text=Hello" target="_blank" rel="nofollow" aria-label="WhatsApp Chat Button"></a>
