import React from 'react';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const staffData = [
  {
    name: 'Dr. Akshata.Hallikeri',
    position: 'Naturopathy Physician and Acupuncturist',
    description: (
      <p>
        Dr. Akshata.Hallikeri is a Naturopathy Physician and Acupuncturist. She has completed medical degree in Naturopathy & Yogic Sciences from Rajeev Gandhi Health University, Bengaluru. She is a registered Physician with KAUPB (Karnataka State Council for Indian Medicine and AYUSH, Govt. of India).<br/>
        Dr. Akshata.Hallikeri practiced extensively under father and mentor Deenabandhu Hallikeri, an accomplished Surgeon and philanthropist. Her other mentors include Dr. Sangram of New Castel, United Kingdom (UK), and Dr. Chittaranjan of Bolton, UK.<br/>
        She has practiced as a Naturopathy physician and acupuncture specialist for over 15 years at various institutions such as DG Hallikeri Nursing Home - Hubli, Clay - Mumbai, Just for Health - Lonavala, Akshoos' Clinic - Bangalore. Dr. Akshata specializes in Naturopathic healing, effective in combating lifestyle disorders.<br/>
        <b>Her treatment regimes cover three holistic spheres:</b><br/>
        {'>>'} Diet management,<br/>
        {'>>'} Fitness of mind and body management through specialized yoga, and<br/>
        {'>>'} Specialized Naturopathy/acupuncture treatment.
      </p>
    ),
    imageUrl: '/DoctorTeam/Akshata.jpeg',
  },
  {
    name: 'Dr Poornima Bk',
    position: 'Wellness Consultant',
    description: (
      <p>
        Dr Poornima Bk is a wellness consultant in the field of Naturopathy, Yoga therapy, and clinical Acupuncture.<br/>
        She pursued her passion by obtaining a graduation from SDM College of Naturopathy and Yogic Sciences (affiliated with Rajiv Gandhi University of Health Sciences) and holds a Master in Acupuncture.<br/>
        Dr. Poornima has over 15 years of experience, having trained at Jindal Nature Care Hospital, Bangalore, and worked at Soukya - International Holistic Health Center, Bangalore, among other centers.<br/>
        She has treated chronic conditions through clinical acupuncture, diet counseling, yoga therapy, and other natural treatments.
      </p>
    ),
    imageUrl: '/DoctorTeam/Poornima.jpeg',
  },
  {
    name: 'Dr. Vrisheeka Suresh',
    position: 'Senior Naturopath | 17 Years of Experience',
    description: <p>'Dr. Vrisheeka Suresh is a highly experienced naturopath with over 17 years of expertise in personalized and holistic healthcare. She specializes in integrating Acupuncture, Diet, and Yoga Therapy to provide comprehensive treatments tailored to individual needs. Her approach emphasizes educating individuals on natural remedies and lifestyle modifications for sustained health and well-being.<br/> 
    <br/><b>Key Areas of Expertise:</b> <br/>Acupuncture Treatments, Diet Consultations, Yoga Therapy Sessions <br/>
    <br/><b>Professional Experience:</b> <br/>KIMS Hospital: Extensive work in women’s health.<br/> Saudi Arabia (KSA): Conducted Yoga Therapy sessions for diverse nationalities.<br/> Navi Mumbai & Jamnagar: Provided Acupuncture treatments and Diet consultations. <br/>
    <br/><b>Current Associations:</b><br/> Mahananjanadriya School & Aksharamaney: Educating and empowering young minds to lead healthy lives. <br/>Elite Badminton Academy: Enhancing players’ focus, concentration, and overall strength through holistic therapies. <br/><br/><b>Consultation Services: </b><br/>Offering Acupuncture, Diet, and Yoga Therapy consultations both online and offline, helping individuals achieve optimal health and balance.'</p>,
    imageUrl: '/DoctorTeam/Vireeshka.jpeg',
  },
  {
    name: 'Dr. Rashmi Bilki',
    position: 'BNYS, Msc (Applied Psychology)',
    description: 'A highly experienced Naturopathy and Yoga Physician with over 20 years of dedicated practice in holistic health and wellness.  Specializing in natural healing methods, including therapeutic yoga, diet management, and lifestyle counselling along with psychological counselling, to promote overall well-being.  Known for a personalized and integrative approach, combining the wisdom of traditional therapies with modern insights to help individuals achieve balance, vitality, and optimal health. Recognized for empowering patients through preventive care and a deep understanding of the mind-body connection.',
    imageUrl: '/DoctorTeam/Rashmi.jpg',
  },
];

const StaffPage = () => {
  return (
    <>
      <div className="page-headerg">
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12">
              <h2 style={{ color: "white", fontFamily: "Circular" }}>Doctors</h2>
            </div>
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <a style={{ color: "white" }} href="/">Home</a>
              <a style={{ color: "white" }} href="#"><MdKeyboardDoubleArrowRight />Doctors</a>
            </div>
          </div>
        </div>
      </div>

      <div className="staff-container">
        {staffData.map((staff, index) => (
          <div
            key={index}
            className={`staff-card ${index % 2 === 0 ? 'normal' : 'reverse'}`}
          >
            <img
              src={staff.imageUrl}
              alt={staff.name}
              className="staff-image"
            />
            <div className="staff-info">
              <h2>{staff.name}</h2>
              <h4>{staff.position}</h4>
              <div className="staff-description">{staff.description}</div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .staff-container {
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .staff-card {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; /* Space between image and text */
          margin-bottom: 40px;
        }
        .staff-card.reverse {
          flex-direction: row-reverse; /* Zig-zag layout */
        }
        .staff-image {
          width: 450px;
          height: 450px;
          border-radius: 8px;
          margin: 0 20px;
          object-fit: cover;
          background-color: #f0f0f0;
        }
        .staff-info {
          max-width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center; /* Center text vertically */
        }
        .staff-info h2 {
          margin: 0 0 10px;
        }
        .staff-info h4 {
          margin: 0 0 10px;
          color: #555;
        }
        .staff-description {
          margin-top: 20px;
          text-align: justify; /* Justify text for better readability */
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .staff-card {
            flex-direction: column; /* Stack layout for mobile */
            align-items: center; /* Center content */
          }
          .staff-card.reverse {
            flex-direction: column; /* Stack layout for mobile */
          }
          .staff-image {
            width: 100%;
            max-width: 300px;
            height: auto;
            margin-bottom: 20px;
          }
          .staff-info {
            text-align: center;
            max-width: 90%;
          }
          .staff-info h2 {
            font-size: 1.5rem;
          }
          .staff-info h4 {
            font-size: 1.2rem;
          }
          .staff-description {
            text-align: justify; /* Ensure justified alignment on mobile */
          }
        }

        @media (max-width: 480px) {
          .staff-info h2 {
            font-size: 1.2rem;
          }
          .staff-info h4 {
            font-size: 1rem;
          }
        }
      `}</style>
    </>
  );
};


export default StaffPage;
