import React from 'react'
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
const Class = () => {
    return (
        <div className="class">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p></p>
                    <h2 >Yoga Types...</h2>
                </div>
                <div className="row">

                    <div className="col-12">
                        <ul id="class-filter">
                            <li data-filter="" className="filter-active"><Link style={{ color: "rgb(135 128 128)" }} to="/yoga">Corporate Yoga</Link></li>
                            <li data-filter=".filter-1"><Link to="" style={{ color: "rgb(135 128 128)" }}>Desktop yoga</Link></li>
                            <li data-filter=".filter-2"><Link to="" style={{ color: "rgb(135 128 128)" }}>Medical Yoga</Link></li>
                            <li data-filter=".filter-3"><Link to="" style={{ color: "rgb(135 128 128)" }}>Fertility yoga</Link></li>
                            <li data-filter=".filter-3"><Link to="" style={{ color: "rgb(135 128 128)" }}>Prenatal yoga</Link></li>
                            <li data-filter=".filter-3"><Link to="" style={{ color: "rgb(135 128 128)" }}>Postnatal yoga</Link></li>
                            {/* <li data-filter=".filter-3"><Link to="" style={{ color: "rgb(135 128 128)" }}>Womens Health</Link></li> */}
                        </ul>
                    </div>

                </div>

                <div className="row class-container">


                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="0.4s">

                        <div className="class-wrap">
                            <div className="class-img">
                                <img src="img/types3.jpg" alt="Image" className="move-image" />
                            </div>
                            <div className="class-text">
                                <div className="class-teacher">
                                    {/* <img src="img/teacher-3.png" alt="Image"/> */}
                                    <h3>Balanced Work Life </h3>
                                    {/* <a href="">+</a> */}
                                </div>
                                <h2>Corporate Yoga</h2>
                                <div className="class-meta">
                                    {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/types111.jpg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-1.png" alt="Image"/> */}
                                        <h3>Stretch, Relax at Your Desk</h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2> Desktop Yoga</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.2s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/types211.jpg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-2.png" alt="Image"/> */}
                                        <h3>Yoga Therapy for Diseases </h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2>Medical Yoga</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/types511.jpg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-5.png" alt="Image"/> */}
                                        <h3> Improves Fertility</h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2>Fertility Yoga</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.6s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/types4.png" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-4.png" alt="Image"/> */}
                                        <h3>Yoga for Healthy Pregnancy</h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2>Prenatal Yoga</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>



                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img  " >
                                    <img src="img/postnatal1111.jpg" alt="Image1" className="move-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-6.png" alt="Image"/> */}
                                        <h3>Experience Healthy Motherhood</h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2>Postnatal Yoga</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    {/* <p></p> */}
                    <h2 >Wellness...</h2>
                    <p></p>
                </div>
               
                <div className="row class-container">
                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/New folder/ph4.jpg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-5.png" alt="Image"/> */}
                                        <h3> Pediatric Health
                                        </h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2> Healer of Children</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/y2.jpeg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-5.png" alt="Image"/> */}
                                        <h3> Womens Health</h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2>Aims at Balanced Life</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/gd.jpeg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                        {/* <img src="img/teacher-5.png" alt="Image"/> */}
                                        <h3>Geriatric Health</h3>
                                        {/* <a href="">+</a> */}
                                    </div>
                                    <h2>Health needs of the Elderly.</h2>
                                    <div className="class-meta">
                                        {/* <p><i className="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i className="far fa-clock"></i>9:00 - 10:00</p> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

</div>
               
                <div className="row class-container">
                    <div className="col-lg-4 col-md-6 col-sm-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
                        <Link to="">
                            <div className="class-wrap">
                                <div className="class-img">
                                    <img src="img/fehe.jpg" alt="Image" className="zoom-image" />
                                </div>
                                <div className="class-text">
                                    <div className="class-teacher">
                                   
                                        <h3>Fertility Health
                                        </h3>
                                        
                                    </div>
                                    <h2> <FaArrowRight />Nutrition</h2>
                                    <h2> <FaArrowRight />Fertility yoga</h2>
                                    <h2> <FaArrowRight />Fertility nutrition</h2>
                                </div>
                            </div>
                        </Link>
                    </div>


</div>


                </div>
            </div>
        </div>
    )
}

export default Class;