// import React, { useState,useEffect } from "react";
// import 'jquery-ui-dist/jquery-ui';
// import { MdKeyboardDoubleArrowRight } from "react-icons/md";


// const Contact = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0); // Scrolls to the top of the page
// }, []);
//   const [status, setStatus] = useState("Submit");

//   const [info, setInfo] = useState({ name: '', subject: '', email: '',mobileNumber: '', message: '' });

//   const handleChange = (event) => {
//     setInfo({
//       ...info,
//       [event.target.name]: event.target.value
//     })
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(info);
//     setStatus("Sending...");


//     let response = await fetch(`/api/contactuspage`, {
//       method: "POST",
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//         'Accept': 'application/json',
//         //'Content-Type': 'application/json',
//         "Content-Type": "application/json;charset=utf-8",
//       },
//       body: JSON.stringify(info),
//       //body: JSON.stringify(details),
//     });
//     //console.log(details);
//     console.log(info);
//     setStatus("Submit");
//     setInfo({ name: '', subject: '', email: '',mobileNumber:'', message: '' });
//     let result = await response.json();
//     //alert(result.status);
//   };

//   return (
//     <>
//       <div className="page-headerc">
//         <div className="container resposnive_breadmcrum">
//           <div className="row">
//             <div className="col-12" style={{fontFamily:"Circular"}}>
//               <h2 style={{ color: "black" }}>Contact</h2>
//             </div>
//             <div className="col-12" style={{fontFamily:"Circular"}}>
//               <a href="/" style={{ color: "black" }}>Home</a>
//               <a href=""  style={{ color: "black" }}><MdKeyboardDoubleArrowRight/>contact</a>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="contact">
//         <div className="container">
//           <div className="section-header text-center" data-wow-delay="0.1s">
//             <p>Get In Touch</p>
//             <h2>For Any Query</h2>
//           </div>
//           <div className="row">
//             <div className="col-12">
//               <div className="row">
//                 <div className="col-md-4 contact-item " data-wow-delay="0.s">
//                   <i className="fa fa-map-marker-alt"></i>
//                   <div className="contact-text">
//                     <h2>Location</h2>
//                     <p>Bengaluru</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4 contact-item" data-wow-delay="0.s">
//                   <i className="fa fa-phone-alt"></i>
//                   <div className="contact-text">
//                     <h2>Phone</h2>
//                     <p>+916366889406</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4 contact-item " data-wow-delay="0.s">
//                   <i className="far fa-envelope"></i>
//                   <div className="contact-text">
//                     <h2>Email</h2>
//                     <p>info@anantasaukhyam.com</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-12" data-wow-delay="0.1s">
//               <div className="contact-form">
//                 <div id="success"></div>

//                 <form name="sentMessage" id="contactForm" onSubmit={handleSubmit} novalidate="novalidate">
//                   <div className="control-group">
//                     <input type="text" className="form-control" name="name" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" value={info.name} onChange={handleChange} />
//                     <p className="help-block text-danger"></p>
//                   </div>
//                   <div className="control-group">
//                     <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" value={info.email} onChange={handleChange} />
//                     <p className="help-block text-danger"></p>
//                   </div>
//                   <div className="control-group">
//                     <input
//                       type="tel"  // Use type "tel" for input that expects a telephone number
//                       pattern="[0-9]{10}"  // Set the pattern to exactly 10 digits
//                       className="form-control"
//                       name="mobileNumber"
//                       id="mobileNumber"
//                       placeholder="Your Mobile Number"
//                       required="required"
//                       data-validation-required-message="Please enter your 10-digit mobile number"
//                       value={info.mobileNumber}
//                       onChange={handleChange}
//                     />
//                     <p className="help-block text-danger"></p>
//                   </div>

//                   <div className="control-group">
//                     <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" value={info.subject} onChange={handleChange} />
//                     <p className="help-block text-danger"></p>
//                   </div>
//                   <div className="control-group">
//                     <textarea className="form-control" name="message" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message" value={info.message} onChange={handleChange}></textarea>
//                     <p className="help-block text-danger"></p>
//                   </div>
//                   <div>
//                     <button className="btn" type="submit" id="sendMessageButton">{status}</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Contact;



import React, { useState, useEffect } from "react";
import 'jquery-ui-dist/jquery-ui';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import '@fortawesome/fontawesome-free/css/all.min.css';  // Import Font Awesome CSS

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const [status, setStatus] = useState("Submit");
  const [info, setInfo] = useState({ name: '', subject: '', email: '', mobileNumber: '', message: '' });

  const handleChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(info);
    setStatus("Sending...");

    let response = await fetch(`/api/contactuspage`, {
      method: "POST",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(info),
    });

    console.log(info);
    setStatus("Submit");
    setInfo({ name: '', subject: '', email: '', mobileNumber: '', message: '' });
    let result = await response.json();
  };

  return (
    <>
      <div className="page-headerc">
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <h2 >Contact</h2>
            </div>
            <div className="col-12" style={{ fontFamily: "Circular" }}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight />contact</a>
            </div>
          </div>
        </div>
      </div>

      <div className="contact">
        <div className="container">
          <div className="section-header text-center" data-wow-delay="0.1s">
            <p>Get In Touch</p>
            <h2>For Any Query</h2>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 contact-item" data-wow-delay="0.s">
                  <i className="fa fa-map-marker-alt"></i>
                  <div className="contact-text">
                    <h2>Location</h2>
                    <p>Bengaluru</p>
                  </div>
                </div>
                <div className="col-md-4 contact-item" data-wow-delay="0.s">
                  <i className="fa fa-phone-alt"></i>
                  <div className="contact-text">
                    <h2>Phone</h2>
                    <p>+916366889406</p>
                  </div>
                </div>
                <div className="col-md-4 contact-item" data-wow-delay="0.s">
                  <i className="far fa-envelope"></i>
                  <div className="contact-text">
                    <h2>Email</h2>
                    <p>info@anantasaukhyam.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12" data-wow-delay="0.1s">
              <div className="contact-form">
                <div id="success"></div>
                <form name="sentMessage" id="contactForm" onSubmit={handleSubmit} noValidate="novalidate">
                  <div className="control-group">
                    <input type="text" className="form-control" name="name" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" value={info.name} onChange={handleChange} />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" value={info.email} onChange={handleChange} />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <input
                      type="tel"  // Use type "tel" for input that expects a telephone number
                      pattern="[0-9]{10}"  // Set the pattern to exactly 10 digits
                      className="form-control"
                      name="mobileNumber"
                      id="mobileNumber"
                      placeholder="Your Mobile Number"
                      required="required"
                      data-validation-required-message="Please enter your 10-digit mobile number"
                      value={info.mobileNumber}
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" value={info.subject} onChange={handleChange} />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="control-group">
                    <textarea className="form-control" name="message" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message" value={info.message} onChange={handleChange}></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div>
                    <button className="btn" type="submit" id="sendMessageButton">{status}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
