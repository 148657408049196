import React,{useEffect} from "react"; 
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import "../css/style.css"
const Acupenture = () => {
     useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
    return (
        <div>
           <div className="page-headerac" >
            <div className="container resposnive_breadmcrum">
              <div className="row">
                <div className="col-12" style={{fontFamily:"Circular"}}>
    
                  <h2 >Acupuncture</h2>
                </div>
                <div className="col-12" style={{fontFamily:"Circular"}}>
                  <a href="/" 
                  >Home</a>
                  <a href="#" 
                  ><MdKeyboardDoubleArrowRight/> Acupuncture </a>
                </div>
                <img src="Yin_and_Yang_symbol.png" alt="about"  className="yinyanglogo" />
              </div>
            </div>
          </div>
          <div className="about wow fadeInUp" data-wow-delay="0.1s" style={{marginTop:'-30px'}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                <div >
                        <p><br /><br /></p>
                        
                    </div>
                    <div className="about-img about wow fadeInUp" data-wow-delay="0.3s" style={{marginTop:'-10px'}}>
                    <img src="img/Acupuncturist1.jpg" alt="about" className="move-image"  style={{height:"400px",marginTop:"-130px"}}/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="section-header text-left">
                        <p>Acupuncture</p>
                        <h2>Let Your Energy Heal</h2><br />
                        
                    </div>
                    <div className="about-text about wow fadeInUp" data-wow-delay="0.6s" style={{marginTop:'-70px'}}>
                        <p>
                         
                        
                        <b>Acupuncture </b>is an ancient method of healing and a component practice of  <b style={{color:"black"}}>Traditional Chinese Medicine (TCM)</b> in which thin needles are placed at specific points in the body. It's primarily used to relieve pain but has also been used to treat other conditions like <b>fibromyalgia, migraine, bronchial asthma, gynecological issues, etc.</b> to name a few.</p>
<p><b>How Does Acupuncture Work:</b> The practice seeks to release the flow of the body's vital energy or "chi" by stimulating points along 14 energy pathways. Designated points are believed to stimulate the central nervous system. This, in turn, releases chemicals into the muscles, spinal cord, and brain. 


                        </p>
                        <p>
                        Some scientists believe that the needles cause the body to release endorphins natural painkillers and may boost blood flow and change brain activity. These biochemical changes may stimulate the body's natural healing abilities and promote physical and emotional well-being.
                        </p>
                       
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row align-items-center">
                
                <div className="col-lg-5 col-md-6">
                     <div className="about-text">
                     <p>
                       
                       Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                       Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                       </p>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6">
                    <div className="about-img">
                        <img src="img/slider2.jpg" alt="about"/>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
        </div>
      )
    }

export default Acupenture
