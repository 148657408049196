import React, { useState,useEffect } from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const BlogDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
    const [status, setStatus] = useState("Submit");

    const [info, setInfo] = useState({ name:'', website:'', email:'', message:'' });
    
    const handleChange = (event) => {
        setInfo({
            ...info,
            [event.target.name]: event.target.value
        })
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(info);
        setStatus("Sending...");
        
    
        let response = await fetch("/api/blogpage", {
        method: "POST",
        headers: {
            'Access-Control-Allow-Origin':'*',
            'Accept': 'application/json',
            //'Content-Type': 'application/json',
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(info),
        //body: JSON.stringify(details),
        });
        //console.log(details);
        console.log(info);
        setStatus("Submit");
        setInfo({ name:'', website:'', email:'', message:'' });
        let result = await response.json();
        //alert(result.status);
    };

    return (
        <>
            <div className="page-headery" >
                <div className="container resposnive_breadmcrum">
                    <div className="row">
                        <div className="col-12">

                            <h2 >Blog</h2>
                        </div>
                        <div className="col-12" style={{fontFamily:"Circular"}}>
                            <a href="/" >Home</a>
                            <a href="" ><MdKeyboardDoubleArrowRight/>Blog</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="single-content wow fadeInUp" >

                                <img src="img1/blog1.jpg" style={{ height: "300px", width: "500px" }} className="move-image" />
                                {/* <h2> <b>DR SWARNA GOWRI
                                    BNYS, PGDHHM,  </b>
                                </h2>
                                <h3>Consultant -Naturopathy, acupuncture, yoga physician</h3>
                                <p>
                                    With over two decades of rich experience, Dr Swarna has been curing people using a holistic approach to wellbeing that integrates naturopathy, acupuncture, pressure therapy, yoga and counseling. She has also worked extensively with corporates to positively impact the physical and mental wellbeing of employees.
                                </p> */}
                                <p>
                                    <h2> <b>Acupuncture:</b> </h2> Acupuncture is an age-old healing form of alternative medicine and a component practice of traditional Chinese medicine (TCM) in which thin needles are placed at specific points in the body. It's primarily used to relieve pain but has also been used to treat other conditions like fibromyalgia, migraine, bronchial asthma, gynecological issues etc. to name a few.
                                </p>
                                <p>
                                    {/* Quisque arcu nulla, convallis nec orci vel, suscipit elementum odio. Curabitur volutpat velit non diam tincidunt sodales. Nullam sapien libero, bibendum nec viverra in, iaculis ut eros. */}
                                </p>
                                <h3>How Does Acupuncture Work:  </h3>
                                <p>
                                    The practice seeks to release the flow of the body's vital energy or "chi" by stimulating points along 14 energy pathways. Designated points are believed to stimulate the central nervous system. This, in turn, releases chemicals into the muscles, spinal cord, and brain.
                                    Some scientists believe that the needles cause the body to release endorphins - natural painkillers -- and may boost blood flow and change brain activity. These biochemical changes may stimulate the body's natural healing abilities and promote physical and emotional well-being.

                                </p>
                                {/* <p>
                                Mauris quis arcu finibus, posuere dolor eu, viverra felis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In porta, ex vitae accumsan facilisis, nisi tellus dictum metus, quis fringilla dui tellus in tellus. Praesent pharetra orci at vehicula posuere. Sed molestie fringilla lorem, vel imperdiet tortor blandit at. Quisque non ultrices lorem, eget rhoncus orci. Fusce porttitor placerat diam et mattis. Nam laoreet, ex eu posuere sollicitudin, sem tortor pellentesque ipsum, quis mattis purus lectus ut lacus. Integer eu risus ac est interdum scelerisque.
                            </p> */}
                                <h4>Naturopathy Diet- Let your Prakriti heal You   </h4>
                                <p>
                                    Right dietary changes play vital role on resolving, reversing and restoring to a healthy body, so that it plays a vital role for enhancing fertility .
                                </p>
                                <p>
                                    Yoga: Is designed to experience the YOGA (oneness) with the SELF.Specific fertility enhancing Medical Yoga is taught to get the best results.
                                    All of these holistic approach work to support body, help to improve outcomes and keep body & mind comfortable and relaxed during the cycle. In research, these protocols show an increase in pregnancy rates, live birth rates, and a decrease in miscarriage.

                                </p>
                                {/* <p>
                                Nam sodales scelerisque nunc sed convallis. Vestibulum facilisis porta erat, sit amet pharetra tortor blandit id. Nunc velit tellus, consectetur sed convallis in, tincidunt finibus nulla. Integer vel ex in mauris tincidunt tincidunt nec sed elit. Etiam pretium lectus lectus, sed aliquet erat tristique euismod. Praesent faucibus nisl augue, ac tempus libero pellentesque malesuada. Vivamus iaculis imperdiet laoreet. Aliquam vel felis felis. Proin sed sapien erat. Etiam a quam et metus tempor rutrum. Curabitur in faucibus justo. Etiam imperdiet iaculis urna.
                            </p> */}
                            </div>
                            <div className="single-tags wow fadeInUp">
                                <a href="/yoga">Corporate yoga</a>
                                <a href="/yoga">Desktop yoga</a>
                                <a href="/yoga">Medical Yoga</a>
                                <a href="/yoga">Fertility yoga</a>
                                <a href="/yoga">Prenatal yoga</a>
                                <a href="/yoga">Postnatal yoga</a>
                                <a href="/yoga">Womens Health</a>
                            </div>
                            <div className="single-bio wow fadeInUp">
                                <div className="single-bio-img">
                                    <img src="img1/a1.jpeg" />
                                </div>
                                <div className="single-bio-text">
                                    <h3>DR SWARNA GOWRI
                                        BNYS, PGDHHM.
                                    </h3>
                                    <h4>Consultant -Naturopathy, Acupuncture, Yoga physician.</h4>
                                    <p>
                                        With over two decades of rich experience, Dr Swarna has been curing people using a holistic approach to wellbeing that integrates naturopathy, acupuncture, pressure therapy, yoga and counseling. She has also worked extensively with corporates to positively impact the physical and mental wellbeing of employees.
                                    </p>
                                </div>
                            </div>

                            {/* <div className="single-related wow fadeInUp">
                            <h2>Related Post</h2>
                            <div className="owl-carousel related-slider">
                                <div className="post-item">
                                    <div className="post-img">
                                        <img src="img/post-1.jpg" />
                                    </div>
                                    <div className="post-text">
                                        <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        <div className="post-meta">
                                            <p>By<a href="">Admin</a></p>
                                            <p>In<a href="">Design</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-item">
                                    <div className="post-img">
                                        <img src="img/post-2.jpg" />
                                    </div>
                                    <div className="post-text">
                                        <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        <div className="post-meta">
                                            <p>By<a href="">Admin</a></p>
                                            <p>In<a href="">Design</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-item">
                                    <div className="post-img">
                                        <img src="img/post-3.jpg" />
                                    </div>
                                    <div className="post-text">
                                        <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        <div className="post-meta">
                                            <p>By<a href="">Admin</a></p>
                                            <p>In<a href="">Design</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-item">
                                    <div className="post-img">
                                        <img src="img/post-4.jpg" />
                                    </div>
                                    <div className="post-text">
                                        <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                        <div className="post-meta">
                                            <p>By<a href="">Admin</a></p>
                                            <p>In<a href="">Design</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                            {/* <div className="single-comment wow fadeInUp">
                            <h2>3 Comments</h2>
                            <ul className="comment-list">
                                <li className="comment-item">
                                    <div className="comment-body">
                                        <div className="comment-img">
                                            <img src="img/user.jpg" />
                                        </div>
                                        <div className="comment-text">
                                            <h3><a href="">Josh Dunn</a></h3>
                                            <span>01 Jan 2045 at 12:00pm</span>
                                            <p>
                                                Lorem ipsum dolor sit amet elit. Integer lorem augue purus mollis sapien, non eros leo in nunc. Donec a nulla vel turpis tempor ac vel justo. In hac platea dictumst.
                                            </p>
                                            <a className="btn" href="">Reply</a>
                                        </div>
                                    </div>
                                </li>
                                <li className="comment-item">
                                    <div className="comment-body">
                                        <div className="comment-img">
                                            <img src="img/user.jpg" />
                                        </div>
                                        <div className="comment-text">
                                            <h3><a href="">Josh Dunn</a></h3>
                                            <p><span>01 Jan 2045 at 12:00pm</span></p>
                                            <p>
                                                Lorem ipsum dolor sit amet elit. Integer lorem augue purus mollis sapien, non eros leo in nunc. Donec a nulla vel turpis tempor ac vel justo. In hac platea dictumst.
                                            </p>
                                            <a className="btn" href="">Reply</a>
                                        </div>
                                    </div>
                                    <ul className="comment-child">
                                        <li className="comment-item">
                                            <div className="comment-body">
                                                <div className="comment-img">
                                                    <img src="img/user.jpg" />
                                                </div>
                                                <div className="comment-text">
                                                    <h3><a href="">Josh Dunn</a></h3>
                                                    <p><span>01 Jan 2045 at 12:00pm</span></p>
                                                    <p>
                                                        Lorem ipsum dolor sit amet elit. Integer lorem augue purus mollis sapien, non eros leo in nunc. Donec a nulla vel turpis tempor ac vel justo. In hac platea dictumst.
                                                    </p>
                                                    <a className="btn" href="">Reply</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div> */}

                            <div className="comment-form wow fadeInUp">
                                <h2>Leave a comment</h2>

                                <form  onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label for="name">Name *</label>
                                        <input type="text" className="form-control" id="name"  name="name" value={info.name} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Email *</label>
                                        <input type="email" className="form-control" id="email" name="email" value={info.email} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="website">Website</label>
                                        <input type="url" className="form-control" id="website" name="website" value={info.website} onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label for="message">Message *</label>
                                        <textarea id="message" cols="30" rows="5"  name="message" className="form-control" value={info.message} onChange={handleChange}></textarea>
                                    </div>
                                    <div className="form-group">
                                        {/* <input type="submit" value="Post Comment" className="btn" /> */}
                                        <button className="btn" type="submit" id="sendMessageButton">{status}</button>
                                    </div>
                                </form>
                            </div>
                        </div>




                        <div className="col-lg-4">
                            <div className="sidebar">
                                <div className="sidebar-widget wow fadeInUp">
                                    <div className="search-widget">
                                        <form>
                                            {/* <input className="form-control" type="text" placeholder="Search Keyword" /> 
                                            <button className="btn"><i className="fa fa-search">{status}</i></button>*/}
                                        </form>
                                    </div>
                                </div>

                                <div className="sidebar-widget wow fadeInUp">
                                    <h2 className="widget-title">Recent Post</h2>
                                    <div className="image-widget">
                                        <a href="#"><img src="img1/1111.jpg" alt="Image" /></a>
                                        {/* <div className="post-item">
                                        <div className="post-img">
                                            <img src="img1/11.jpg" />
                                        </div>
                                        <div className="post-text">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                            <div className="post-meta">
                                                <p>By<a href="">Admin</a></p>
                                                <p>In<a href="">Design</a></p>
                                            </div>
                                        </div>
                                    </div> */}

                                        {/* <div className="post-item">
                                        <div className="post-img">
                                            <img src="img/post-2.jpg" />
                                        </div>
                                        <div className="post-text">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                            <div className="post-meta">
                                                <p>By<a href="">Admin</a></p>
                                                <p>In<a href="">Design</a></p>
                                            </div>
                                        </div>
                                    </div> */}
                                        <a href="#"><img src="img1/11111.jpg" alt="Image" /></a>
                                        {/* <div className="post-item">
                                        <div className="post-img">
                                            <img src="img/post-3.jpg" />
                                        </div>
                                        <div className="post-text">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                            <div className="post-meta">
                                                <p>By<a href="">Admin</a></p>
                                                <p>In<a href="">Design</a></p>
                                            </div>
                                        </div>
                                    </div> */}

                                        {/* <div className="post-item">
                                        <div className="post-img">
                                            <img src="img/post-4.jpg" />
                                        </div>
                                        <div className="post-text">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                            <div className="post-meta">
                                                <p>By<a href="">Admin</a></p>
                                                <p>In<a href="">Design</a></p>
                                            </div>
                                        </div>
                                    </div> */}

                                        {/* <div className="post-item">
                                        <div className="post-img">
                                            <img src="img/post-5.jpg" />
                                        </div>
                                        <div className="post-text">
                                            <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                            <div className="post-meta">
                                                <p>By<a href="">Admin</a></p>
                                                <p>In<a href="">Design</a></p>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>

                                <div className="sidebar-widget wow fadeInUp">
                                    <div className="image-widget">
                                        <a href="#"><img src="img1/111111.jpg" alt="Image" /></a>
                                        {/* <a href="#"><img src="img1/1.jpg" alt="Image" /></a> */}
                                    </div>
                                </div>

                                {/* <div className="sidebar-widget wow fadeInUp">
                                <div className="tab-post">
                                    <ul className="nav nav-pills nav-justified">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="pill" href="#featured">Featured</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="pill" href="#popular">Popular</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="pill" href="#latest">Latest</a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div id="featured" className="container tab-pane active">
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img1/b1.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img1/b2.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img1/b3.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img1/b4.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img1/b5.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="popular" className="container tab-pane fade">
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-1.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-2.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-3.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-4.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-5.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="latest" className="container tab-pane fade">
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-1.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-2.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-3.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-4.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-item">
                                                <div className="post-img">
                                                    <img src="img/post-5.jpg" />
                                                </div>
                                                <div className="post-text">
                                                    <a href="">Lorem ipsum dolor sit amet consec adipis elit</a>
                                                    <div className="post-meta">
                                                        <p>By<a href="">Admin</a></p>
                                                        <p>In<a href="">Design</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                <div className="sidebar-widget wow fadeInUp">
                                    <div className="image-widget">
                                        <a href="#"><img src="img1/b6.jpg" alt="Image" /></a>
                                    </div>
                                </div>

                                <div className="sidebar-widget wow fadeInUp">
                                    <h2 className="widget-title">Categories</h2>
                                    <div className="category-widget">
                                        <ul>
                                            <li><a href="/yoga">Yoga</a><span></span></li>
                                            <li><a href="/consultation">Consultation</a><span></span></li>
                                            <li><a href="/diet">Diet</a><span></span></li>
                                            <li><a href="/heathylifestle">Healthy LifeStyle</a><span></span></li>
                                            <li><a href="/acupenture">Acupuncture</a><span></span></li>
                                            <li><a href="/counselleing">Counseling</a><span></span></li>
                                            <li><a href="/trainingyoga">Trainings on Yoga</a><span></span></li>
                                            <li><a href="/seminars">Seminars to Corporate</a><span></span></li>
                                            <li><a href="/stressdeletion">Stress Deletion</a><span></span></li>
                                            <li><a href="/naturopathy">Naturopathy</a><span></span></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="sidebar-widget wow fadeInUp">
                                    <div className="image-widget">
                                        <a href="#"><img src="img1/b7.jpg" alt="Image" /></a>
                                    </div>
                                </div>

                                {/* <div className="sidebar-widget wow fadeInUp">
                                <h2 className="widget-title">Tags Cloud</h2>
                                <div className="tag-widget">
                                    <a href="">National</a>
                                    <a href="">International</a>
                                    <a href="">Economics</a>
                                    <a href="">Politics</a>
                                    <a href="">Lifestyle</a>
                                    <a href="">Technology</a>
                                    <a href="">Trades</a>
                                </div>
                            </div> */}

                                {/* <div className="sidebar-widget wow fadeInUp">
                                <h2 className="widget-title">Text Widget</h2>
                                <div className="text-widget">
                                    <p>
                                        Lorem ipsum dolor sit amet elit. Integer lorem augue purus mollis sapien, non eros leo in nunc. Donec a nulla vel turpis tempor ac vel justo. In hac platea nec eros. Nunc eu enim non turpis id augue.
                                    </p>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}
export default BlogDetails;
