import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const Trainingyoga = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
}, []);
  return (
    <div>
      <div className="page-headery" >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{fontFamily:"Circular"}}>

              <h2 >Training on Yoga</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight/>Training on Yoga </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about wow fadeInUp" data-wow-delay="0.1s" style={{marginTop:'10px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div >
                <p><br /><br /></p>

              </div>
              <div className="trainingonyoga trainingonyoga">
                <img src="img/c yoga 5.jpg" alt="about" style={{  marginTop: "100px" }} className="move-image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-header text-left" style={{marginTop:'10px'}}>
                <p>Training on Yoga</p>
                <h2>Yoga for Corporate World.</h2><br />

              </div>
              <div className="about-text" style={{marginTop:'-40px'}}>
                <p>


                  Welcome to <b > AnantaSaukhyam,</b> your gateway to comprehensive and transformative Yoga Training Programs. Whether you're looking to deepen your personal practice or become a certified yoga instructor, our training programs are designed to provide you with the knowledge, skills, and inspiration to embark on a meaningful yoga journey.<br />
                  <b>Why Choose Our Yoga Training Programs:</b><br />
                  At AnantaSaukhyam, we take pride in offering a holistic and authentic approach to yoga education. Here's why our programs stand out:<br />
                  <b>1. Experienced Instructors:</b> Our training programs are led by highly experienced and certified yoga instructors who are passionate about sharing their expertise and guiding you on your path.<br />
                  <b>2. Comprehensive Curriculum:</b> Our curriculum covers a wide range of yoga topics, including asana practice, pranayama, meditation, anatomy, philosophy, and teaching methodology.<br />
                  <b>3. Personalized Guidance:</b> We understand that every student is unique. That's why our programs offer personalized guidance, ensuring that you receive the support and attention you need to succeed.<br />
                  <b>4. Yoga Philosophy:</b> Explore the rich tradition of yoga philosophy and its practical application in modern life, gaining a deeper understanding of yoga beyond the physical postures.

                </p>


              </div>
            </div>
          </div>
        </div>
      </div>

<br/>

      <div className="about wow fadeInUp" data-wow-delay="0.1s" style={{marginTop:'-20px'}}>
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-5 col-md-6">
              <div className="about-text">
                <p>
                <b>Benefits:</b><br />
               <b>1. Improved Employee Health:</b>  Regular yoga practice has been shown to improve physical fitness, boost immunity, and reduce the risk of common health issues. Our seminars introduce employees to yoga's physical benefits, promoting a healthier workforce.<br />
               <b>2. Stress Reduction:</b>  Workplace stress is a prevalent issue that can affect job satisfaction and productivity. Our seminars teach stress management techniques, including yoga and meditation, to help employees navigate high-pressure situations with ease.<br />
               <b> 3. Increased Productivity:</b> When employees are physically and mentally fit, they tend to be more productive and focused. Yoga promotes mental clarity, concentration, and creativity, leading to improved job performance.<br />
               </p></div>
            </div>
            <div className="col-lg-7 col-md-6">
              <br />
              <div className="about-img" >
              <img src="img/corporate yoga 1.jpg" alt="about"  className="move-image"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Trainingyoga
