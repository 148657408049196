import React,{useEffect} from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const Stressdeletion = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
}, []);
  return (
    <div>
      <div className="page-headerst" >
        <div className="container resposnive_breadmcrum">
          <div className="row">
            <div className="col-12" style={{fontFamily:"Circular"}}>

              <h2 >Stress Deletion</h2>
            </div>
            <div className="col-12" style={{fontFamily:"Circular"}}>
              <a href="/" >Home</a>
              <a href="" ><MdKeyboardDoubleArrowRight/>Stress Deletion </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about wow fadeInUp" data-wow-delay="0.1s"  style={{marginTop:'-160px'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div >
                <p><br /><br /></p>

              </div>
              <div className="stress" >
                <img
                  src="img1/stress1.jpg"
                  alt="about"
                  // style={{ width: "100%", height: "110%", objectFit: "cover", marginTop: "-90px" }}
                  className="move-image"
                />
              </div>



            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-header text-left" style={{marginTop:'10px'}}>
                <p>Stress Deletion</p>
                <h2>Rejuvenate and Restart</h2><br />

              </div>
              <div className="about-text" style={{marginTop:'-30px'}}>
                <p>



                  Welcome to our <b>Stress Deletion Program</b> - a <b>unique sessions</b> conducted by <b >AnantaSaukhyam,</b>     we believe stress needs to be deleted rather than managed
                  In today's fast-paced world, stress has become a prevalent issue that affects our physical health, mental well-being, and overall quality of life. We believe in the transformative power of an individual to alleviate stress and promote inner peace.
                  We empower you to conquer stress and find tranquility

                  <br /> <br />
                  <b >Stress is a natural response to life's challenges, but chronic stress can have detrimental effects on our health. It can lead to anxiety, insomnia, fatigue, and a range of physical ailments. Our Stress Deletion Program is designed to address stress at its roots and provide you with effective tools to manage it.</b> <br /><br />



                </p>


              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row align-items-center">
            
            <div className="col-lg-5 col-md-6">
                 <div className="about-text">
                 <p>
                   
                   Counseling Sessions are designed to understand the  Individual Nature ( Swabhava). Helps them to understand there Nature, qualities & remove the myths of Mind. Develop a balanced Emotional well-being & incorporate it in Day to day life to reach  their highest potential 
                   Healthy lifestyle- are designed to understand the circadian rhythm of the Individual & help the person to get the best of there of Life 
                   </p>
                </div>
            </div>
            <div className="col-lg-7 col-md-6">
                <div className="about-img">
                    <img src="img/slider2.jpg" alt="about"/>
                </div>
            </div>
        </div>
    </div>
</div> */}
    </div>
  )
}

export default Stressdeletion
