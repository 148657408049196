import React from 'react'
import { Link } from 'react-router-dom';

const Discount=()=> {
  return (
    <div class="discount wow zoomIn" data-wow-delay="0.1s">
          <Link to="/className">
            <div class="container">
                <div class="section-header text-center">
                    <p><b>AnantaSaukhyam</b></p>
                    <h2>To Bring Health to All  <span> Experience Infinite Health in U (Ananta Saukhyam) </span> </h2>
                </div>
                <div class="container discount-text">
                    <p>
                    Spread the message all can Lead a Healthy life. It brings QUALITY TO Human life..... 
                    </p>
                    {/* <a href='/contact' className="btn"></a> */}
                </div>
            </div>
            </Link>
        </div>
  )
}

export default Discount;