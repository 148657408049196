// import React, { useState, useEffect } from 'react';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import './Slider.css'; // Import CSS for styling

// const Slider11 = () => {
//   // State to keep track of current slide index
//   const [currentSlide, setCurrentSlide] = useState(0);

//   // Data for slides (images and text)
//   const slides = [
//     {
//       heading: 'Yoga for health',
//       desc: 'Experience Oneness',
//       images: ['./new/yoga.png'],
//     },
//     {
//       heading: 'Acupuncture',
//       desc:  "Let The Energy Heal You",
//       images: ['./new/a.png'],
//     },
//     {
//       heading: 'Wellness to Corporate',
//       desc: "Integrated Clinic | Yoga | Workshop | Seminars | Talks",
//       images: ['./new/corporate.png'],
//     },
//     {
//       heading: "Diet and Nutrition",
//       desc: "Let Your Food Heal You",
//       images: ['./new/img10.png'],
//     },
//     {
//       heading: "Counseling",
//       desc: "Remove | Restart | Uninstall & Install | Reboot",
//       images: ['./new/councling.png'],
//     },
//     {
//       heading: "Naturopathy",
//       desc: "Nature cure",
//       images: ['./new/Naturo.jpg'],
//     },
//   ];

//   // Function to handle next slide
//   const nextSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
//   };

//   // Function to handle previous slide
//   const prevSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
//   };

//   useEffect(() => {
//     // Automatically slide to the next slide every 3 seconds
//     const interval = setInterval(nextSlide, 3000);

//     // Cleanup function to clear the interval when component unmounts
//     return () => clearInterval(interval);
//   }, [currentSlide]); // Re-run effect when the current slide changes

//   return (
//     <div className="slider11-container">
//       <div className="slider11">
//         <div className="slider11-left">
//           {/* Display text of current slide */}
//           <div className="slider11-desktop-caption">
//             <h2>{slides[currentSlide].heading}</h2>
//             <p>{slides[currentSlide].desc}</p>
//           </div>
//         </div>
//         <div className="slider11-right">
//           {/* Set background images for the right side */}
//           {slides[currentSlide].images.map((image, index) => (
//             <div key={index} className="slider11-bg-image" style={{ backgroundImage: `url(${image})` }} />
//           ))}
//         </div>
//       </div>
//       <div className="slider11-mobile-caption">
//         <h2>{slides[currentSlide].heading}</h2>
//         <p>{slides[currentSlide].desc}</p>
//       </div>
//       {/* Buttons for navigating to next and previous slides */}
//       <button className="slider11-prev" onClick={prevSlide}>
//         <FaArrowLeft />
//       </button>
//       <button className="slider11-next" onClick={nextSlide}>
//         <FaArrowRight />
//       </button>
//     </div>
//   );
// };

// export default Slider11;

// import React, { useState, useEffect } from 'react';
// import { FaArrowLeft, FaArrowRight, FaPhoneAlt, FaVideo } from 'react-icons/fa';
// import './Slider.css'; // Import CSS for styling

// const Slider11 = () => {
//   // State to keep track of current slide index
//   const [currentSlide, setCurrentSlide] = useState(0);

//   // Data for slides (images and text)
//   const slides = [
//     {
//       heading: 'Yoga for health',
//       desc: 'Experience Oneness',
//       images: ['./new/yoga.png'],
//     },
//     {
//       heading: 'Acupuncture',
//       desc: "Let The Energy Heal You",
//       images: ['./new/a11.png'],
//     },
//     {
//       heading: 'Wellness to Corporate',
//       desc: "Integrated Clinic | Yoga | Workshop | Seminars | Talks",
//       images: ['./new/c.png'],
//     },
//     {
//       heading: "Diet and Nutrition",
//       desc: "Let Your Food Heal You",
//       images: ['./new/diet2.png'],
//     },
//     {
//       heading: "Counseling",
//       desc: "Remove | Restart | Uninstall & Install | Reboot",
//       images: ['./new/councling.png'],
//     },
//     {
//       heading: "Naturopathy",
//       desc: "Nature cure",
//       images: ['./new/Naturo.jpg'],
//     },
//   ];

//   // Function to handle next slide
//   const nextSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
//   };

//   // Function to handle previous slide
//   const prevSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
//   };

//   useEffect(() => {
//     // Automatically slide to the next slide every 3 seconds
//     const interval = setInterval(nextSlide, 3000);

//     // Cleanup function to clear the interval when component unmounts
//     return () => clearInterval(interval);
//   }, [currentSlide]); // Re-run effect when the current slide changes

//   return (
//     <div className="slider11-container">
//       {/* Mobile call box */}
//       <div className="slider11-call-box">
//         <a href="tel:+916366889406">
//           <FaPhoneAlt /> +916366889406
//         </a>
//         <a href="/Videoconsultancy">
//           <FaVideo />  Video Consultation
//         </a>
//       </div>
//       <div className="slider11">
//         <div className="slider11-left">
//           {/* Display text of current slide */}
//           <div className="slider11-desktop-caption">
//             {/* Add the heading here */}
//             <h1 className='k'>Integrated and Holistic Approach</h1>
//             <h2>{slides[currentSlide].heading}</h2>
//             <p>{slides[currentSlide].desc}</p>
//           </div>
//         </div>
//         <div className="slider11-right">
//           {/* Set background images for the right side */}
//           {slides[currentSlide].images.map((image, index) => (
//             <div key={index} className="slider11-bg-image" style={{ backgroundImage: `url(${image})` }} />
//           ))}
//         </div>
//       </div>
//       <div className="slider11-mobile-caption">
//         <h2>{slides[currentSlide].heading}</h2>
//         <p>{slides[currentSlide].desc}</p>
//       </div>
//       {/* Buttons for navigating to next and previous slides */}
//       <button className="slider11-prev" onClick={prevSlide}>
//         <FaArrowLeft />
//       </button>
//       <button className="slider11-next" onClick={nextSlide}>
//         <FaArrowRight />
//       </button>
//     </div>
//   );
// };

// export default Slider11;



import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight, FaPhoneAlt, FaVideo } from 'react-icons/fa';
import './Slider.css'; // Import CSS for styling
import HandWithLeaves from '../img/leaves3.png'; // Import your image

const Slider11 = () => {
  // State to keep track of current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  // Data for slides (images and text)
  const slides = [
    {
      heading: 'Yoga for health',
      desc: 'Experience Oneness',
      images: ['./new/yoga.png'],
    },
    {
      heading: 'Acupuncture',
      desc: "The Energy Heals You",
      images: ['./new/a11.png'],
    },
    {
      heading: 'Wellness to Corporate',
      desc: "Integrated Clinic | Yoga | Workshop | Seminars | Talks",
      images: ['./new/c.png'],
    },
    {
      heading: "Diet and Nutrition",
      desc: "Let Your Food Heal You",
      images: ['./new/diet2.png'],
    },
    {
      heading: "Counseling",
      desc: "Remove | Restart | Uninstall & Install | Reboot",
      images: ['./new/councling.png'],
    },
    {
      heading: "Naturopathy",
      desc: "Nature cures",
      images: ['./new/Naturo.jpg'],
    },
  ];

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  useEffect(() => {
    // Automatically slide to the next slide every 3 seconds
    const interval = setInterval(nextSlide, 3000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, [currentSlide]); // Re-run effect when the current slide changes

  return (
    <div className="slider11-container">
      {/* Mobile call box */}
      <div className="slider11-call-box">
        <a href="tel:+916366889406">
          <FaPhoneAlt /> +916366889406
        </a>
        <a href="/Videoconsultancy">
          <FaVideo /> Video Consultation
        </a>
      </div>
      <div className="slider11">
        <div className="slider11-left">
          {/* Display text of current slide */}
          <div className="slider11-desktop-caption">
            {/* Add the heading here */}
            <h4 className='k'>Comprehensive & Unified Methodology </h4>
            <h2>{slides[currentSlide].heading}</h2>
            <p>{slides[currentSlide].desc}</p>
            <img src={HandWithLeaves} alt="Hand with leaves" className="diagonal-image" />
          </div>
        </div>
        <div className="slider11-right">
          {/* Set background images for the right side */}
          {slides[currentSlide].images.map((image, index) => (
            <div key={index} className="slider11-bg-image" style={{ backgroundImage: `url(${image})` }} />
          ))}
        </div>
      </div>
      <div className="slider11-mobile-caption">
        <h2>{slides[currentSlide].heading}</h2>
        <p>{slides[currentSlide].desc}</p>
      </div>
      {/* Buttons for navigating to next and previous slides */}
      <button className="slider11-prev" onClick={prevSlide}>
        <FaArrowLeft />
      </button>
      <button className="slider11-next" onClick={nextSlide}>
        <FaArrowRight />
      </button>
    </div>
  );
};

export default Slider11;
